import { ILoggingClient } from '@/src/integrations/logging/client'

export class QuantcastClient implements ILoggingClient {
  email: string | undefined
  constructor(readonly quantcastId: string) {}

  setUserProperty(id: string | null, email: string | undefined) {
    this.email = email
  }

  sendEvent(eventName?: string, property?: object) {
    window._qevents?.push({
      qacct: this.quantcastId,
      uid: this.email,
      ...(property ?? {}),
    })
  }

  sendHomePageEvent() {
    this.sendEvent('homePageEvent', {
      labels: '_fp.event.Sign Up',
      event: 'refresh',
    })
  }

  sendSignupEvent() {
    this.sendEvent('signup')
  }
}
