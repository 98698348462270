import React, { useEffect, useState } from 'react'
import {
  getLanguage,
  SUPPORTED_LANGUAGES,
  TEXTS_WITH_LOCALE,
} from '@/src/locale/locale.util'

export const UserLanguageContext = React.createContext({
  language: getLanguage(),
  isEnglish: getLanguage() === 'en',
  isKorean: getLanguage() === 'ko',
  textWithLocale: TEXTS_WITH_LOCALE[getLanguage()],
  changeLanguage: (state: SUPPORTED_LANGUAGES) => {
    return state
  },
})

interface UserLanguageContextProviderProps {
  children: React.ReactNode
}

class UserLanguageLSM<T> {
  key: string
  readonly parser: (rawData: any) => T

  constructor() {
    this.key = 'userLanguage'
    this.parser = (rawData) => rawData
  }

  private getRawData(): any | undefined {
    const jsonString = localStorage.getItem(this.key)

    if (!jsonString) {
      return undefined
    }

    try {
      return JSON.parse(jsonString)
    } catch (error) {
      return jsonString
    }
  }

  getData(): T | undefined {
    const rawData = this.getRawData()

    if (rawData === undefined) {
      return undefined
    }

    return this.parser(rawData)
  }

  setData(language: T) {
    localStorage.setItem(this.key, JSON.stringify(language))
  }
}

const userLanguageLSM = new UserLanguageLSM<SUPPORTED_LANGUAGES>()

const UserLanguageContextProvider = ({
  children,
}: UserLanguageContextProviderProps) => {
  const [language, setLanguage] = useState<SUPPORTED_LANGUAGES>('en')

  const changeLanguage = (language: SUPPORTED_LANGUAGES) => {
    userLanguageLSM.setData(language)
    setLanguage(language)
    return language
  }

  const isEnglish = language === 'en'
  const isKorean = language === 'ko'
  const textWithLocale = TEXTS_WITH_LOCALE[language]

  useEffect(() => {
    changeLanguage(userLanguageLSM.getData() ?? getLanguage())
  }, [])

  return (
    <UserLanguageContext.Provider
      value={{ language, isEnglish, isKorean, textWithLocale, changeLanguage }}
    >
      {children}
    </UserLanguageContext.Provider>
  )
}

export default UserLanguageContextProvider
