const LandingDocumentPageSrc = `${process.env.assetsCDNURL}/landingpage/landing_document_page.jpg`
const LandingDocumentPageKRSrc = `${process.env.assetsCDNURL}/landingpage/landing_document_page-kr.jpg`
const FoundersTaskSrc = `${process.env.assetsCDNURL}/landingpage/founders_task.jpg`
const FoundersTaskKRSrc = `${process.env.assetsCDNURL}/landingpage/founders_task-kr.jpg`
const ResourceCard1Src = `${process.env.assetsCDNURL}/landingpage/resource_card1.svg`
const ResourceCard2Src = `${process.env.assetsCDNURL}/landingpage/resource_card2.svg`
const ResourceCard1KRSrc = `${process.env.assetsCDNURL}/landingpage/resource_card1-kr.png`
const ResourceCard2KRSrc = `${process.env.assetsCDNURL}/landingpage/resource_card2-kr.png`
const CursorGreenSrc = `${process.env.assetsCDNURL}/landingpage/cursor-green.svg`
const CursorPurpleSrc = `${process.env.assetsCDNURL}/landingpage/cursor-purple.svg`
const BringEverythingTogetherSrc = `${process.env.assetsCDNURL}/landingpage/bring_everything_together.svg`
const BringEverythingCursorSrc = `${process.env.assetsCDNURL}/landingpage/bring_everthing_cursor.svg`
const LetTheToolDoTheWorkSrc = `${process.env.assetsCDNURL}/landingpage/videos/scale_your_partners.mp4`
const BeOnTopOfYourGameSrc = `${process.env.assetsCDNURL}/landingpage/videos/connect_all.mp4`
const MobileExampleImgSrc = `${process.env.assetsCDNURL}/landingpage/mobile_example_img.png`
const AppStoreSrc = `${process.env.assetsCDNURL}/landingpage/app_store.svg`
const GooglePlaySrc = `${process.env.assetsCDNURL}/landingpage/google_play.svg`
const FounderProjectImgSrc = `${process.env.assetsCDNURL}/landingpage/founder_project_page.jpg`
const FounderProjectKRImgSrc = `${process.env.assetsCDNURL}/landingpage/founder_project_page-kr.jpg`
const FounderDocumentPageSrc = `${process.env.assetsCDNURL}/landingpage/founder_document_page.jpg`
const FounderDocumentKRPageSrc = `${process.env.assetsCDNURL}/landingpage/founder_document_page-kr.jpg`
const SaveAnythingSrc = `${process.env.assetsCDNURL}/landingpage/save_anything.jpg`
const CreateBetterSrc = `${process.env.assetsCDNURL}/landingpage/videos/create_better.mp4`
const OrganizeLessSrc = `${process.env.assetsCDNURL}/landingpage/videos/organize_less.mp4`
const ConnectAllSrc = `${process.env.assetsCDNURL}/landingpage/videos/connect_all.mp4`
const MaximizeBandwidthSrc = `${process.env.assetsCDNURL}/landingpage/maximize_bandwidth.jpg`
const SingleSourceOfTruthSrc = `${process.env.assetsCDNURL}/landingpage/videos/single_source_of_truth.mp4`
const ProgressOverProcessSrc = `${process.env.assetsCDNURL}/landingpage/videos/progress_over_process.mp4`
const StudentsDocumentPageSrc = `${process.env.assetsCDNURL}/landingpage/students_document_page.jpg`
const StudentProjectImgSrc = `${process.env.assetsCDNURL}/landingpage/students_project_page.jpg`
const StudentsTaskSrc = `${process.env.assetsCDNURL}/landingpage/students_task.jpg`
const StudentsDocumentPageKRSrc = `${process.env.assetsCDNURL}/landingpage/students_document_page-kr.jpg`
const StudentProjectKRImgSrc = `${process.env.assetsCDNURL}/landingpage/students_project_page-kr.jpg`
const StudentsTaskKRSrc = `${process.env.assetsCDNURL}/landingpage/students_task-kr.png`
const ResearchFasterSrc = `${process.env.assetsCDNURL}/landingpage/save_anything.jpg`
const WriteSmarterSrc = `${process.env.assetsCDNURL}/landingpage/videos/create_better.mp4`
const StressFreeSrc = `${process.env.assetsCDNURL}/landingpage/stress_free.svg`
const StressFreeCursorSrc = `${process.env.assetsCDNURL}/landingpage/stress_free_cursors.svg`
const InvestorsDocumentPageSrc = `${process.env.assetsCDNURL}/landingpage/investors_document_page.jpg`
const InvestorProjectImgSrc = `${process.env.assetsCDNURL}/landingpage/investors_project_page.jpg`
const InvestorsTaskSrc = `${process.env.assetsCDNURL}/landingpage/investors_task.jpg`
const DiligentEveryStepSrc = `${process.env.assetsCDNURL}/landingpage/save_anything.jpg`
const DontMissAnyDetailsSrc = `${process.env.assetsCDNURL}/landingpage/videos/create_better.mp4`
const ScaleYourPartnersSrc = `${process.env.assetsCDNURL}/landingpage/videos/scale_your_partners.mp4`
const CreatorsDocumentPageSrc = `${process.env.assetsCDNURL}/landingpage/creators_document_page.jpg`
const CreatorsDocumentPageKRSrc = `${process.env.assetsCDNURL}/landingpage/creators_document_page-kr.jpg`
const CreatorProjectImgSrc = `${process.env.assetsCDNURL}/landingpage/creators_project_page.jpg`
const CreatorProjectKRImgSrc = `${process.env.assetsCDNURL}/landingpage/creators_project_page-kr.png`
const CreatorsTaskSrc = `${process.env.assetsCDNURL}/landingpage/creators_task.jpg`
const CreatorsTaskKRSrc = `${process.env.assetsCDNURL}/landingpage/creators_task-kr.png`
const FindYourEdgeSrc = `${process.env.assetsCDNURL}/landingpage/save_anything.jpg`
const JustFocusOnContentSrc = `${process.env.assetsCDNURL}/landingpage/videos/create_better.mp4`
const BeYourOwnInspirationSrc = `${process.env.assetsCDNURL}/landingpage/videos/connect_all.mp4`
const UsageImgSrc = `${process.env.assetsCDNURL}/landingpage/usage.svg`
const CollaborationImgSrc = `${process.env.assetsCDNURL}/landingpage/collaboration.svg`
const IntegrationImgSrc = `${process.env.assetsCDNURL}/landingpage/integration.svg`
const FeaturesImgSrc = `${process.env.assetsCDNURL}/landingpage/features.svg`
const OrganizeImgSrc = `${process.env.assetsCDNURL}/landingpage/organize.svg`
const ResourcesImgSrc = `${process.env.assetsCDNURL}/landingpage/resources.svg`
const AdminImgSrc = `${process.env.assetsCDNURL}/landingpage/admin%26security.svg`

const TimPaoliniSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/tim_paolini.png`
const TimSpencerSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/tim_spencer.jpg`
const MarcoMarinucciSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/marco_marinucci.png`
const TaeyangLeeSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/taeyang_lee.jpg`
const SeheeChunSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/sehee_chun.jpg`
const EthanRomeroSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/ethan_romero.png`
const RachaelLeeSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/rachael_lee.png`
const EunjiLeeSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/rachael_lee.png`
const SeohyunJeongSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/seohyun_jeong.jpg`
const KaseyRohSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/kasey_roh.png`
const CamCrowderSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/cam_crowder.png`
const ShinaJeongSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/shina-jeong.png`
const SangminHongSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/sangmin_hong.jpg`
const JosephMavericksSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/joseph_mavericks.jpg`
const Q2hanSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/q2han.png`
const DaeyeonJinSrc = `${process.env.assetsCDNURL}/landingpage/testimonials/daeyeon_jin.jpg`

const GoogleLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/google-logo.png`
const StanfordLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/stanford-logo.png`
const SendBirdLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/sendbird-logo.png`
const ChannelTalkLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/channeltalk-logo.png`
const IterativeLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/iterative-logo.svg`
const MarkaiLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/markai-logo.png`
const MindTheBridgeLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/mindthebridge-logo.png`
const TossLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/toss-logo.png`
const GrowAndBetterLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/grow&better-logo.png`
const YaleLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/yale-logo.png`
const YonseiLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/yonsei-logo.png`
const BerkeleyLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/berkeley-logo.svg`
const HarvardLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/harvard-logo.svg`
const MichiganLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/michigan-logo.svg`
const VVLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/vv-logo.png`
const AtlandventuresLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/atlandventures-logo.svg`
const StormVenturesLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/stormventures-logo.svg`
const KakaoVenturesLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/kakaoventures-logo.png`
const NextransLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/nextrans-logo.png`
const JosephMavericksLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/joseph_mavericks-logo.png`
const Q2hanLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/q2han-logo.png`
const JosephImgSrc = `${process.env.assetsCDNURL}/landingpage/logos/joseph-logo.svg`
const RedHeadAcademicImgSrc = `${process.env.assetsCDNURL}/landingpage/logos/redheadacademic-logo.svg`
const Q2HanImgSrc = `${process.env.assetsCDNURL}/landingpage/logos/q2han-logo.svg`
const SNULogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/snu-logo.png`
const SamsungLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/samsung-logo.png`
const KaistLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/kaist-logo.png`
const DunamuLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/dunamu-logo.png`
const BluepointLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/bluepoint-logo.png`
const SopoongLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/sopoong-logo.png`
const AppStoreImgSrc = `${process.env.assetsCDNURL}/landingpage/app_store.svg`
const GooglePlayImgSrc = `${process.env.assetsCDNURL}/landingpage/logos/google_play_link.svg`
const InstagramLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/instagram-logo.png`
const LinkedInLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/linked_in-logo.png`
const FacebookLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/facebook-logo.png`
const TwitterLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/twitter-logo.png`
const YoutubeLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/youtube-logo.png`

const articlesBlogSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/articles_blog.jpg`
const businessPlanSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/business_plan.jpg`
const contentManagementSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/content_management.jpg`
const groupProjectSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/group_project.jpg`
const investmentMemoSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/investment_memo.jpg`
const marketResearchSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/market_research.jpg`
const meetingNoteSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/meeting_note.jpg`
const pitchDeckSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/pitch_deck.jpg`
const proposalSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/proposal.jpg`
const researchPaperSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/research_paper.jpg`
const secondBrainSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/second_brain.jpg`
const studyGuideSrc = `${process.env.assetsCDNURL}/landingpage/howToTyped/study_guide.jpg`

const FoundersICPSrc = `${process.env.assetsCDNURL}/landingpage/founders_icp.jpg`
const StudentsICPSrc = `${process.env.assetsCDNURL}/landingpage/students_icp.jpg`
const InvestorsICPSrc = `${process.env.assetsCDNURL}/landingpage/investors_icp.jpg`
const CreatorsICPSrc = `${process.env.assetsCDNURL}/landingpage/creators_icp.jpg`

const AfterTypedImgSrc = `${process.env.assetsCDNURL}/landingpage/after_typed.jpg`
const BeforeTypedImgSrc = `${process.env.assetsCDNURL}/landingpage/before_typed.jpg`

const ServiceBrochureSrc = `${process.env.assetsCDNURL}/landingpage/service_brochure-en.pdf`
const ServiceBrochureKRSrc = `${process.env.assetsCDNURL}/landingpage/service_brochure-kr_v2.pdf`
const TypedWhitepaperSrc = `${process.env.assetsCDNURL}/landingpage/Typed_whitepaper.pdf`

export enum ICP {
  founders = 'founders',
  students = 'students',
  investors = 'investors',
  creators = 'creators',
}

interface ICPCardData {
  title: string
  bodyText: string
  imgSrc: string
  path: string
}

interface Testimonial {
  mainImgSrc: string
  logoImgSrc: string
  comments: string[]
  name: string
  job: string
}

interface HeroImage {
  heading1Text: string
  heading3Text: string
  body1Texts: string[]
  mainImgSrc: string
  taskImgSrc: string
  subImgSrc: string
}

interface FeatureIntroduction {
  headingTexts: string[]
  bodyTexts: string[]
  subtitle: string
  mainMediaSrc: string
  subImgSrc?: string
}

export enum HowToTypedEnum {
  meetingNote = 'meetingNote',
  businessPlan = 'businessPlan',
  pitchDeck = 'pitchDeck',
  researchPaper = 'researchPaper',
  groupProject = 'groupProject',
  studyGuide = 'studyGuide',
  investmentMemo = 'investmentMemo',
  proposal = 'proposal',
  marketResearch = 'marketResearch',
  contentManagement = 'contentManagement',
  articlesBlog = 'articlesBlog',
  secondBrain = 'secondBrain',
}

interface PricingPlanCard {
  title: string
  titleDesc: string[]
  price: (isYearly?: boolean) => string
  priceDesc: (isYearly?: boolean) => string[]
  btnText: string
  btnPath: string
  detailTitle: string
  detailContents: { isActive: boolean; content: string }[]
}

interface HeaderItem {
  content: string
  url: string
}

interface WebsiteText {
  header: {
    button: {
      features: HeaderItem
      experience: HeaderItem
      pricing: HeaderItem
      blog: HeaderItem
      ['typed academy']: HeaderItem
      ['log in']: HeaderItem
      ['start typing']: HeaderItem
    }
    dropdown: {
      experience: HeaderItem[]
      learn: HeaderItem[]
    }
  }
  footer: {
    logoBoxTxt: string
    mobileAppLinks: { imgSrc: string; url: string }[]
    SNSLinks: { imgSrc: string; url: string }[]
    linkItems: {
      Typed: HeaderItem
      Product: HeaderItem
      Features: HeaderItem
      Pricing: HeaderItem
      Blog: HeaderItem
      Brochure: HeaderItem
      Privacy: HeaderItem
      Experience: HeaderItem
      Founders: HeaderItem
      Students: HeaderItem
      Investors: HeaderItem
      Creators: HeaderItem
      Resources: HeaderItem
      ['Typed Academy']: HeaderItem
      ['White Paper']: HeaderItem
      Company: HeaderItem
      About: HeaderItem
      Careers?: HeaderItem
      ['Contact Us']: HeaderItem
      Notice?: HeaderItem
    }
  }
  heroImageFixedTxts: {
    getStartedBtnTxt: string
  }
  ['How to Typed']: {
    title: string
    bodyText1: string
    bodyText2: string
    items: {
      [key in HowToTypedEnum]: {
        imgSrc: string
        txt: string
        path: string
      }
    }
  }
  startTyped: {
    headingTxt: string
    bodyTxt: string
    getStarted: string
  }
  joinTypers: {
    heading3Txt: string
  }
  icps: {
    ['See how you can Typed']: string
    ['See how others Typed']: string
    items: {
      [key in ICP]: ICPCardData
    }
    learnText: string
  }
  landingPage: {
    heroImage: {
      resourceCard1ImgSrc: string
      resourceCard2ImgSrc: string
      greenCursorSrc: string
      purpleCursorSrc: string
    } & Omit<HeroImage, 'heading3Text' | 'subImgSrc'>
    joinTypersImgSrcs: string[]
    testimonial: Testimonial
    featureIntroductions: Omit<FeatureIntroduction, 'subtitle'>[]
    afterTyped: {
      heading2Txt: string
      subHeading2Txt?: string
      beforeTypedTxt: string
      beforeTypedSrc: string
      afterTypedTxt: string
      afterTypedSrc: string
    }
    recommendMobileApp: {
      mobileImgSrc: string
      headingTexts: string[]
      bodyTexts: string[]
      appStoreSrc: string
      appStoreUrl: string
      googlePlaySrc: string
      googlePlayUrl: string
    }
  }
  featuresPage: {
    heroImage: HeroImage
    featureIntroductions: FeatureIntroduction[]
  }
  foundersPage: {
    heroImage: HeroImage
    featureIntroductions: FeatureIntroduction[]
    testimonials: Testimonial[]
    joinTypersImgSrcs: string[]
  }
  studentsPage: {
    heroImage: HeroImage
    featureIntroductions: FeatureIntroduction[]
    testimonials: Testimonial[]
    joinTypersImgSrcs: string[]
  }
  investorsPage: {
    heroImage: HeroImage
    featureIntroductions: FeatureIntroduction[]
    testimonials: Testimonial[]
    joinTypersImgSrcs: string[]
  }
  creatorsPage: {
    heroImage: HeroImage
    featureIntroductions: FeatureIntroduction[]
    testimonials: Testimonial[]
    joinTypersImgSrcs: string[]
  }
  pricingPage: {
    heroLayout: {
      heading1Txt: string
      ['billed monthly']: string
      ['billed yearly']: string
      save: string
      savePercentage: string
      freePlan: PricingPlanCard
      proPlan: {
        ['best value']: string
      } & PricingPlanCard
      enterprisePlan: Omit<PricingPlanCard, 'priceDesc'>
      compareBtnTxt: string
      ['are you a student']: string
      ['check FAQ']: string[]
      joinTypersImgSrcs: string[]
      pricingPlanTable: {
        freePlan: {
          title: string
          btnTxt: string
          btnPath: string
        }
        proPlan: {
          title: string
          btnTxt: string
          btnPath: string
        }
        enterprisePlan: {
          title: string
          btnTxt: string
          btnPath: string
        }
        items: {
          name: string
          imgSrc: string
          details: {
            name: string
            free: undefined | string
            pro: undefined | string
            enterprise: undefined | string
          }[]
        }[]
      }
    }
    faqs: {
      title: string
      contents: { question: string; answers: string[] }[]
      ['look for something else']: string
      ['see more']: string
      ['see more url']: string
    }
  }
}

const englishWebsiteText: WebsiteText = {
  header: {
    button: {
      features: { content: 'Features', url: '/features' },
      experience: { content: 'Experience', url: '' },
      pricing: { content: 'Pricing', url: '/pricing' },
      blog: { content: 'Blog', url: 'https://typed.do/blog/' },
      ['typed academy']: {
        content: 'Typed Academy',
        url: 'https://company.typed.do/academy',
      },
      ['log in']: { content: 'Log in', url: '/login' },
      ['start typing']: { content: 'Start Typing', url: '/signup' },
    },
    dropdown: {
      experience: [
        {
          content: 'Founders',
          url: '/founders',
        },
        {
          content: 'Students',
          url: '/students',
        },
        {
          content: 'Investors',
          url: '/investors',
        },
        {
          content: 'Creators',
          url: '/creators',
        },
      ],
      learn: [
        {
          content: 'Tutorial',
          url: 'https://youtube.com/playlist?list=PL40xZMKHcUHC4tHmEOFxsbnrY-0wpXkxj',
        },
        {
          content: 'Use Cases',
          url: 'https://typed.do/blog/category/use-case/',
        },
        {
          content: 'Book Onboarding',
          url: 'https://calendly.com/business-canvas/typed-onboarding-en',
        },
        { content: 'Help Center', url: 'https://intercom.help/typed/en/' },
      ],
    },
  },
  footer: {
    logoBoxTxt: 'Where work gets done.',
    mobileAppLinks: [
      {
        imgSrc: AppStoreImgSrc,
        url: 'https://apps.apple.com/app/typed-save-and-collect-urls/id1606667024',
      },
      {
        imgSrc: GooglePlayImgSrc,
        url: 'https://play.google.com/store/apps/details?id=com.typed.application',
      },
    ],
    SNSLinks: [
      { imgSrc: FacebookLogoSrc, url: 'https://www.facebook.com/Typed.do' },
      {
        imgSrc: YoutubeLogoSrc,
        url: 'https://www.youtube.com/channel/UCnYq2JY2XUbr6UMn06b0RVw',
      },
      { imgSrc: TwitterLogoSrc, url: 'https://twitter.com/typedhq?lang=en' },
      {
        imgSrc: LinkedInLogoSrc,
        url: 'https://www.linkedin.com/company/team-typed',
      },
      { imgSrc: InstagramLogoSrc, url: 'https://www.instagram.com/typed.hq/' },
    ],
    linkItems: {
      Typed: { content: 'Typed', url: '' },
      Product: { content: 'Product', url: '/' },
      Features: { content: 'Features', url: '/features' },
      Pricing: { content: 'Pricing', url: '/pricing' },
      Blog: { content: 'Blog', url: 'https://typed.do/blog' },
      Brochure: { content: 'Brochure', url: ServiceBrochureSrc },
      Privacy: { content: 'Privacy', url: '/privacy' },
      Experience: { content: 'Experience', url: '' },
      Founders: { content: 'Founders', url: '/founders' },
      Students: { content: 'Students', url: '/students' },
      Investors: { content: 'Investors', url: '/investors' },
      Creators: { content: 'Creators', url: '/creators' },
      Resources: { content: 'Resources', url: '' },
      ['Typed Academy']: {
        content: 'Typed Academy',
        url: 'https://company.typed.do/academy',
      },
      ['White Paper']: { content: 'White Paper', url: TypedWhitepaperSrc },
      Company: { content: 'Company', url: '' },
      About: {
        content: 'About',
        url: 'https://company.typed.do/en',
      },
      ['Contact Us']: {
        content: 'Contact Us',
        url: 'https://form.typeform.com/to/SIgqZxJW',
      },
    },
  },
  heroImageFixedTxts: {
    getStartedBtnTxt: 'Get started',
  },
  ['How to Typed']: {
    title: 'How to Typed',
    bodyText1: 'See which use case is right for your team.',
    bodyText2: 'See which use case is right for you.',
    items: {
      meetingNote: {
        imgSrc: meetingNoteSrc,
        txt: 'Meeting Note',
        path: 'https://typed.do/blog/use-case/teams-meeting-notes/',
      },
      businessPlan: {
        imgSrc: businessPlanSrc,
        txt: 'Business Plan',
        path: '',
      },
      pitchDeck: {
        imgSrc: pitchDeckSrc,
        txt: 'Pitch Deck',
        path: 'https://typed.do/blog/use-case/founders-pitch-deck/',
      },
      researchPaper: {
        imgSrc: researchPaperSrc,
        txt: 'Research Paper',
        path: 'https://typed.do/blog/use-case/use-case-students-from-research-to-writing-a-report/',
      },
      groupProject: {
        imgSrc: groupProjectSrc,
        txt: 'Group Project',
        path: 'https://typed.do/blog/use-case/students-group-projects/',
      },
      studyGuide: {
        imgSrc: studyGuideSrc,
        txt: 'Study Guide',
        path: 'https://typed.do/blog/use-case/students-study-guide/',
      },
      investmentMemo: {
        imgSrc: investmentMemoSrc,
        txt: 'Investment Memo',
        path: '',
      },
      proposal: { imgSrc: proposalSrc, txt: 'Proposal', path: '' },
      marketResearch: {
        imgSrc: marketResearchSrc,
        txt: 'Market Research',
        path: 'https://typed.do/blog/use-case/market-research/',
      },
      contentManagement: {
        imgSrc: contentManagementSrc,
        txt: 'Content Management',
        path: 'https://medium.com/entrepreneur-s-handbook/how-one-simple-tool-measurably-improved-my-content-creation-productivity-afd19f141d3',
      },
      articlesBlog: {
        imgSrc: articlesBlogSrc,
        txt: 'Articles / Blog',
        path: 'https://typed.do/blog/use-case/blog-article/',
      },
      secondBrain: {
        imgSrc: secondBrainSrc,
        txt: 'Second Brain',
        path: 'https://josephmavericks.medium.com/how-to-grow-a-second-brain-as-a-content-creator-c589f5fdae25',
      },
    },
  },
  startTyped: {
    headingTxt: 'Don’t just type, Typed.',
    bodyTxt: 'Just do it once, in Typed.',
    getStarted: 'Get started',
  },
  joinTypers: {
    heading3Txt: 'Join Typers changing the way we work and share knowledge.',
  },
  icps: {
    items: {
      founders: {
        title: 'Founders',
        bodyText: "You're all in this together.",
        imgSrc: FoundersICPSrc,
        path: '/founders',
      },
      students: {
        title: 'Students',
        bodyText: 'Spend less, perform better.',
        imgSrc: StudentsICPSrc,
        path: '/students',
      },
      investors: {
        title: 'Investors',
        bodyText: 'Invest in your time too.',
        imgSrc: InvestorsICPSrc,
        path: '/investors',
      },
      creators: {
        title: 'Creators',
        bodyText: 'Achieve your full potential.',
        imgSrc: CreatorsICPSrc,
        path: '/creators',
      },
    },
    ['See how others Typed']: 'See how others Typed',
    ['See how you can Typed']: 'See how you can Typed',
    learnText: 'Learn',
  },
  landingPage: {
    heroImage: {
      heading1Text: 'Where work gets done.',
      body1Texts: [
        'Accomplish more as an individual or a team.',
        'Meet the next generation of documents in Typed.',
      ],
      mainImgSrc: LandingDocumentPageSrc,
      taskImgSrc: FoundersTaskSrc,
      resourceCard1ImgSrc: ResourceCard1Src,
      resourceCard2ImgSrc: ResourceCard2Src,
      greenCursorSrc: CursorGreenSrc,
      purpleCursorSrc: CursorPurpleSrc,
    },
    joinTypersImgSrcs: [
      GoogleLogoSrc,
      StanfordLogoSrc,
      SendBirdLogoSrc,
      ChannelTalkLogoSrc,
      IterativeLogoSrc,
    ],
    testimonial: {
      mainImgSrc: TimPaoliniSrc,
      logoImgSrc: GoogleLogoSrc,
      comments: [
        '“If you use Google Docs and Drive,',
        'Typed will change your life.',
        'If you don’t, it will still change your life.”',
      ],
      name: 'Tim Paolini',
      job: 'Sales Lead at Google',
    },
    featureIntroductions: [
      {
        headingTexts: ['Bring everything', 'together'],
        bodyTexts: [
          'Create. Collaborate. Organize. Manage.',
          'Do them all in one document.',
        ],
        mainMediaSrc: BringEverythingTogetherSrc,
        subImgSrc: BringEverythingCursorSrc,
      },
      {
        headingTexts: ['Let the tool', 'do the work'],
        bodyTexts: ['Let Typed do the organizing.', 'Focus on Typing better.'],
        mainMediaSrc: LetTheToolDoTheWorkSrc,
      },
      {
        headingTexts: ['Be on top of', 'your game'],
        bodyTexts: [
          'No more lost links or files.',
          'Maximize every ounce of your assets.',
        ],
        mainMediaSrc: BeOnTopOfYourGameSrc,
      },
    ],
    afterTyped: {
      heading2Txt: 'Unleash the productivity within you',
      beforeTypedTxt: 'Before Typed',
      beforeTypedSrc: BeforeTypedImgSrc,
      afterTypedTxt: 'After Typed',
      afterTypedSrc: AfterTypedImgSrc,
    },
    recommendMobileApp: {
      mobileImgSrc: MobileExampleImgSrc,
      headingTexts: ['Save references to', 'Typed Mobile.'],
      bodyTexts: ['Whenever, wherever.', 'Save any references you like.'],
      appStoreUrl:
        'https://apps.apple.com/kr/app/typed-save-and-collect-urls/id1606667024',
      appStoreSrc: AppStoreSrc,
      googlePlayUrl:
        'https://play.google.com/store/apps/details?id=com.typed.application',
      googlePlaySrc: GooglePlaySrc,
    },
  },
  featuresPage: {
    heroImage: {
      heading3Text: 'Features',
      heading1Text: 'There is a better way.',
      body1Texts: [
        'Work smarter, not harder.',
        'Welcome to document 2.0, the future of productivity.',
      ],
      mainImgSrc: FounderDocumentPageSrc,
      taskImgSrc: FoundersTaskSrc,
      subImgSrc: FounderProjectImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['Create better'],
        bodyTexts: [
          'Say goodbye to too many tabs and apps. Our document 2.0 is everything you’ve needed to create better. Research and write while managing your knowledge and tasks--all in one flow. Now, you can share the full context of your work with just a link.',
        ],
        subtitle: 'Think and create beyond an A4 paper.',
        mainMediaSrc: CreateBetterSrc,
      },
      {
        headingTexts: ['Organize less'],
        bodyTexts: [
          'Typed defies folders within folders within folders. Our result-oriented structure helps you to get organized just by getting stuff done. Taskify every stage of your work to keep yourself moving forward. When done, just archive and move on. You’ll find yourself more organized than ever.',
        ],
        subtitle: 'Be organized by default.',
        mainMediaSrc: OrganizeLessSrc,
      },
      {
        headingTexts: ['Connect all'],
        bodyTexts: [
          'Our knowledge network is your second brain that automatically remembers your ideas and actions so that YOU don’t have to. It will guide you to the next step to recognize how much you’ve already done and discover how much more you can do.',
        ],
        subtitle: 'Take advantage of your hard work.',
        mainMediaSrc: ConnectAllSrc,
      },
      {
        headingTexts: ['Save anything'],
        bodyTexts: [
          'Our blazingly fast web-extension and mobile app lets you collect any type of information with just a touch. More importantly, don’t just read-it-later but link to your existing or new documents.',
        ],
        subtitle: 'Find and save anything from anywhere.',
        mainMediaSrc: SaveAnythingSrc,
      },
    ],
  },
  foundersPage: {
    heroImage: {
      heading3Text: 'For Founders',
      heading1Text: 'You’re all in this together.',
      body1Texts: ['Make every teamwork count.'],
      mainImgSrc: FounderDocumentPageSrc,
      taskImgSrc: FoundersTaskSrc,
      subImgSrc: FounderProjectImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['Single Source', 'of Truth'],
        bodyTexts: [
          'We spend 20% of our work looking for but not finding the information we need to get the job done. That’s like hiring five people and having one of them literally never show up to work. Stop the waste now with Typed.',
        ],
        subtitle:
          'Have all the context you need to find what you want, when you want.',
        mainMediaSrc: SingleSourceOfTruthSrc,
      },
      {
        headingTexts: ['Maximize', 'Your Bandwidth'],
        bodyTexts: [
          'Don’t miss any details of progress among your team. Asynchronous collaboration works only if the entire team is on the same page.',
        ],
        subtitle:
          'Maintain your capacity at 100% at any given moment by knowing what’s going on.',
        mainMediaSrc: MaximizeBandwidthSrc,
      },
      {
        headingTexts: ['Progress', 'over Process'],
        bodyTexts: [
          'Our result-oriented structure of knowledge management guides your team to make progress rather than organizing a bunch of folders that are going to be meaningless next month.',
        ],
        subtitle:
          'Yes, process is important. But not as much as making progress.',
        mainMediaSrc: ProgressOverProcessSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: TimSpencerSrc,
        logoImgSrc: MarkaiLogoSrc,
        comments: [
          'Google Drive is a mess.',
          'Typed will help you organize that.',
        ],
        name: 'Tim Spencer',
        job: 'Co-founder at Markai',
      },
      {
        mainImgSrc: MarcoMarinucciSrc,
        logoImgSrc: MindTheBridgeLogoSrc,
        comments: [
          'Knowledge management is critical for organizations to grow.',
          "We've seen it in ourselves and in our partners.",
          'Typed is that tool to grow.',
        ],
        name: 'Marco Marinucci',
        job: 'Founder at Mind the Bridge',
      },
    ],
    joinTypersImgSrcs: [
      SendBirdLogoSrc,
      ChannelTalkLogoSrc,
      MarkaiLogoSrc,
      MindTheBridgeLogoSrc,
    ],
  },
  studentsPage: {
    heroImage: {
      heading3Text: 'For Students',
      heading1Text: 'Spend Less, Perform Better.',
      body1Texts: ['Don’t waste time and effort on things that don’t matter.'],
      mainImgSrc: StudentsDocumentPageSrc,
      taskImgSrc: StudentsTaskSrc,
      subImgSrc: StudentProjectImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['Research Faster'],
        bodyTexts: [
          'From web articles and pdfs to screenshots, our blazingly fast extension takes less than a second to bring your research together. Don’t just read-them-later and lose them. Make your amazing ideas into even more amazing papers and presentations.',
        ],
        subtitle: 'Save and organize any information with just a touch.',
        mainMediaSrc: ResearchFasterSrc,
      },
      {
        headingTexts: ['Write Smarter'],
        bodyTexts: [
          'Stop distracting yourself from the assignment. Be able to access everything you need without having gazilions of tabs open. Instead, invest that time on synthesizing your research to write better.',
        ],
        subtitle: 'Say goodbye to too many tabs.',
        mainMediaSrc: WriteSmarterSrc,
      },
      {
        headingTexts: ['Stress-free', 'Group Projects'],
        bodyTexts: [
          'Group projects fail because of shared responsibility without accountability. Typed is your best group project facilitator. By keeping everyone on the same page, every member is accountable to perform better.',
        ],
        subtitle: 'Tired of doing all the work for your group projects?',
        mainMediaSrc: StressFreeSrc,
        subImgSrc: StressFreeCursorSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: EthanRomeroSrc,
        logoImgSrc: YaleLogoSrc,
        comments: [
          'Typed is the (not-so) secret tool I use to get an advantage in all my classes and produce work that I’m really proud of.',
        ],
        name: 'Ethan Romero',
        job: 'Student at Yale University',
      },
      {
        mainImgSrc: RachaelLeeSrc,
        logoImgSrc: StanfordLogoSrc,
        comments: [
          'You already have enough stress at school.',
          "Don't add more with using the wrong tool.",
          'Typed is my go-to tool for research and writing.',
        ],
        name: 'Rachael Lee',
        job: 'Student at Stanford University',
      },
    ],
    joinTypersImgSrcs: [
      StanfordLogoSrc,
      BerkeleyLogoSrc,
      HarvardLogoSrc,
      MichiganLogoSrc,
      YaleLogoSrc,
    ],
  },
  investorsPage: {
    heroImage: {
      heading3Text: 'For Investors',
      heading1Text: 'Invest in Your Time Too.',
      body1Texts: ['Invest in the right venture by investing in yourselves.'],
      mainImgSrc: InvestorsDocumentPageSrc,
      taskImgSrc: InvestorsTaskSrc,
      subImgSrc: InvestorProjectImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['Be Diligent', 'at Every Step'],
        bodyTexts: [
          'Save and organize any information with just a touch. From newsletters and filings to screenshots, our blazingly fast extension and mobile app takes less than a second to bring your insights together from anywhere.',
        ],
        subtitle: 'Don’t miss an update on your prospects.',
        mainMediaSrc: DiligentEveryStepSrc,
      },
      {
        headingTexts: ['Don’t Miss', 'Any Details'],
        bodyTexts: [
          'A link to google doc is not enough to share the full extent of your hard work. Our document 2.0 allows you to share your work from end to end with the same effort and ease. Don’t leave any blind spots to your shared knowledge.',
        ],
        subtitle: 'More is better.',
        mainMediaSrc: DontMissAnyDetailsSrc,
      },
      {
        headingTexts: ['Scale', 'Your Partners'],
        bodyTexts: [
          'Our result-oriented structure of knowledge management allows you and your team to manage and scale your portfolio to satisfy both ends of the investment.',
        ],
        subtitle: 'Let better organization lead you to better ROI.',
        mainMediaSrc: ScaleYourPartnersSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: KaseyRohSrc,
        logoImgSrc: IterativeLogoSrc,
        comments: [
          'Institutionalizing tribal knowledge is a key for any business to scale.',
          'Typed is a perfect solution built for recording, spreading, and organically structuring knowledge within an organization.',
        ],
        name: 'Kasey Roh',
        job: 'Co-founder at Iterative Venture',
      },
      {
        mainImgSrc: CamCrowderSrc,
        logoImgSrc: VVLogoSrc,
        comments: [
          'Typed brings together scattered pieces of information into one organized workspace. It helps me work faster and better at every step of the way.',
        ],
        name: 'Cam Crowder',
        job: 'Investor at VU Venture Partners',
      },
    ],
    joinTypersImgSrcs: [
      AtlandventuresLogoSrc,
      VVLogoSrc,
      StormVenturesLogoSrc,
      IterativeLogoSrc,
    ],
  },
  creatorsPage: {
    heroImage: {
      heading3Text: 'For Creators',
      heading1Text: 'Achieve Your Full Potential.',
      body1Texts: [
        'Maximize everything at your disposal to create the best content.',
      ],
      mainImgSrc: CreatorsDocumentPageSrc,
      taskImgSrc: CreatorsTaskSrc,
      subImgSrc: CreatorProjectImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['Find Your Edge'],
        bodyTexts: [
          'From articles and images to youtube videos, we know how difficult it can get to stay on top of the trend. It takes less than a second to bring and organize your ideas together for your next content with our blazingly fast extension and mobile app.',
        ],
        subtitle: 'Save any information with just a touch.',
        mainMediaSrc: FindYourEdgeSrc,
      },
      {
        headingTexts: ['Just Focus on', 'Content'],
        bodyTexts: [
          'Stop distracting yourself from generating amazing content. Be able to access everything you need without having gazilions of tabs open to get the job done. Invest that time to be creative, to be a better creator.',
        ],
        subtitle: 'Say goodbye to too many tabs.',
        mainMediaSrc: JustFocusOnContentSrc,
      },
      {
        headingTexts: ['Be Your', 'Own Inspiration'],
        bodyTexts: [
          'Our knowledge network is your second brain that automatically connects your ideas and content together. It will help you recognize how much you’ve already one and discover how much more you can achieve.',
        ],
        subtitle: 'Don’t look elsewhere for inspiration.',
        mainMediaSrc: BeYourOwnInspirationSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: JosephMavericksSrc,
        logoImgSrc: JosephMavericksLogoSrc,
        comments: [
          'After a week of tracking my time use on Typed, the results were striking: I was spending 75% less time organizing my research.',
        ],
        name: 'Joseph Mavericks',
        job: 'Blogger',
      },
      {
        mainImgSrc: Q2hanSrc,
        logoImgSrc: Q2hanLogoSrc,
        comments: [
          'Before I used Typed, it took me so much time to organize and create content. Now I can just create.',
        ],
        name: 'Q2HAN',
        job: 'Youtube Creator',
      },
    ],
    joinTypersImgSrcs: [JosephImgSrc, RedHeadAcademicImgSrc, Q2HanImgSrc],
  },
  pricingPage: {
    heroLayout: {
      heading1Txt: 'Find the Best Value for You.',
      ['billed monthly']: 'Billed Monthly',
      ['billed yearly']: 'Billed Yearly',
      save: 'Save',
      savePercentage: '20%',
      freePlan: {
        title: 'Trial',
        titleDesc: ['For individuals or teams', 'getting started.'],
        price: () => '$0',
        priceDesc: () => ['per user'],
        btnText: 'Sign up for Free',
        btnPath: '/signup',
        detailTitle: "What's included",
        detailContents: [
          { isActive: true, content: '1 document' },
          { isActive: true, content: '1 GB of reference storage' },
          { isActive: true, content: 'Typed Extension' },
          { isActive: true, content: 'Typed Mobile' },
        ],
      },
      proPlan: {
        ['best value']: 'Best value!',
        title: 'Pro',
        titleDesc: ['For power users and', 'fast-growing teams.'],
        price: (isYearly) => (isYearly ? '$8' : '$10'),
        priceDesc: (isYearly) => [
          'per user',
          `billed ${isYearly ? 'yearly' : 'monthly'}`,
        ],
        btnText: 'Sign up Pro',
        btnPath: '/signup',
        detailTitle: 'Everything in Free, plus',
        detailContents: [
          { isActive: true, content: 'Unlimited documents' },
          { isActive: true, content: '10 GB of storage per editor' },
          { isActive: true, content: 'Early access to new features' },
        ],
      },
      enterprisePlan: {
        title: 'Enterprise',
        titleDesc: ['Advanced features and', 'support large items.'],
        price: () => 'Custom pricing!',
        btnText: 'Contact Sales',
        btnPath: 'https://calendly.com/business-canvas/letmeintroducetyped-en',
        detailTitle: 'Everything in Pro, plus',
        detailContents: [
          { isActive: true, content: 'Unlimited reference storage' },
          { isActive: true, content: 'Advanced Knowledge Network' },
          { isActive: true, content: 'Designated success managers' },
          { isActive: true, content: 'Custom contact' },
        ],
      },
      compareBtnTxt: 'Compare Plans',
      ['are you a student']: 'Are you a student?',
      ['check FAQ']: [
        'Apply for Typed Student License in Plans & Billing after sign up.',
      ],
      joinTypersImgSrcs: [
        GoogleLogoSrc,
        StanfordLogoSrc,
        SendBirdLogoSrc,
        ChannelTalkLogoSrc,
        IterativeLogoSrc,
      ],
      pricingPlanTable: {
        freePlan: {
          title: 'Trial',
          btnTxt: 'Sign up for Free',
          btnPath: '/signup',
        },
        proPlan: {
          title: 'Pro',
          btnTxt: 'Subscribe to Pro',
          btnPath: '/signup',
        },
        enterprisePlan: {
          title: 'Enterprise',
          btnTxt: 'Contact sales',
          btnPath: 'https://form.typeform.com/to/SIgqZxJW',
        },
        items: [
          {
            name: 'Usage',
            imgSrc: UsageImgSrc,
            details: [
              {
                name: 'Editors',
                free: 'Unlimited',
                pro: 'Unlimited',
                enterprise: 'Unlimited',
              },
              {
                name: 'Viewers',
                free: 'Unlimited',
                pro: 'Unlimited',
                enterprise: 'Unlimited',
              },
              {
                name: 'Documents',
                free: '1',
                pro: 'Unlimited',
                enterprise: 'Unlimited',
              },
              {
                name: 'Reference Storage',
                free: '1GB per space',
                pro: '10GB per editor',
                enterprise: 'Unlimited',
              },
              {
                name: 'Sharing Permission',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: 'Collaboration',
            imgSrc: CollaborationImgSrc,
            details: [
              {
                name: 'Real-time Collaboration',
                free: undefined,
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Collaboration Space',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Invite Viewers / Guests',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Link Sharing',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: 'Integration',
            imgSrc: IntegrationImgSrc,
            details: [
              {
                name: 'Google Workspace',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Drive',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Tasks',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: 'Features',
            imgSrc: FeaturesImgSrc,
            details: [
              {
                name: 'Typed Web',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Typed Mobile',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Document Library',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Text Highlight',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Inbox',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Web Extension',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Backlink',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Search Function',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Knowledge Network v1',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Recommendation System',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Knowledge Network Insights',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: 'Organize',
            imgSrc: OrganizeImgSrc,
            details: [
              {
                name: 'Space',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Teams',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Projects',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: 'Resource Types',
            imgSrc: ResourcesImgSrc,
            details: [
              {
                name: 'Web References (Articles, Videos, etc.)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'PDF',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Images',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Doc (.doc, .docs)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'PPT (.ppt, .pptx)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Excel (.xlxs)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Memo',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Docs',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Slides',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Sheets',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: 'Admin & Security',
            imgSrc: AdminImgSrc,
            details: [
              {
                name: 'Help Center',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Live Chat',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Single Sign On (SSO/SAML)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Priority Support',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
              {
                name: 'Onboarding',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
              {
                name: 'Dedicated Success Manager',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
              {
                name: 'Custom Contract and Invoicing',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
            ],
          },
        ],
      },
    },
    faqs: {
      title: 'FAQs',
      ['look for something else']: 'Looking for something else?',
      ['see more']: 'See more FAQs!',
      ['see more url']:
        'https://academy.typed.do/faq/pricing-and-payment/pricing-plan',
      contents: [
        {
          question: 'What is different between the pricing plans?',
          answers: [
            'Trial Plan limits the number of documents you can have on each Space to 1. Before the document limit, you are able to do everything Typed provides.',
            'Pro Plan allows you to bypass the 1-document limit. An individual editor will be limited to 10GB worth of reference materials for every Space.',
          ],
        },
        {
          question: 'What happens when I reach my document limit (Trial Plan)?',
          answers: [
            'When you reach the document limit within the Space, you will be restrained to previewing documents from the Home and viewing your resources within your Inbox page. Entry to documents will be denied before upgrading.',
            `To upgrade your plan, go to "Settings & Members" within the Space's Project Panel then to Plans & Billing.`,
          ],
        },
        {
          question: 'How does pricing in Typed work?',
          answers: [
            'Trial Plan will be limited to 1 document across all the members of the Space. Regardless of the number of editors, whether 3 or 20, the document limit is 1. The number of viewers is unlimited and they are not included in the pricing.',
            'Pro Plan is calculated per person in a Space once the 1 document limit has been surpassed. The subscription price is $10 per editor every month, however, if you choose the annual payment (paid in advance), you will have a discounted cost equivalent to $8 per editor every month amounting to $96. Payment is carried out by the owner of the Space.',
          ],
        },
        {
          question: 'How does pricing for Teams/Enterprises work?',
          answers: [
            'Contact sales@typed.do to inquire about enterprise pricing.',
          ],
        },
        {
          question: 'Can I collaborate with others while on the Trial Plan?',
          answers: [
            'Collaboration is possible regardless of the currently subscribed plan. Whoever creates the Space first becomes the owner and they are given the authority to invite, remove, and select whether new members are editors or viewers.',
            'Invitees must accept the Space invitation from their email within 7 days to enter the Space.',
          ],
        },
        {
          question: 'Can I belong in multiple Spaces?',
          answers: [
            'You can be invited to or own as many Spaces as you require, however, if you are a Space owner, or multiple, you will be in charge of payment.',
          ],
        },
        {
          question: 'Is there a pricing for students?',
          answers: [
            'Typed provides students with the equivalent of our Pro Plan for a year as a one-year student license. Students can apply for the license under “Plans & Billings” in the settings.',
          ],
        },
      ],
    },
  },
}

const koreanWebsiteText: WebsiteText = {
  header: {
    button: {
      features: { content: '기능 소개', url: '/features' },
      experience: { content: '활용 사례', url: '' },
      pricing: { content: '요금 안내', url: '/pricing' },
      blog: { content: '블로그', url: 'https://typed.do/blog-kr' },
      ['typed academy']: {
        content: '사용자 가이드',
        url: 'https://company.typed.do/academy-kr',
      },
      ['log in']: { content: '로그인', url: '/login' },
      ['start typing']: { content: '무료 체험하기', url: '/signup' },
    },
    dropdown: {
      experience: [
        {
          content: '창업자',
          url: '/founders',
        },
        {
          content: '학생',
          url: '/students',
        },
        {
          content: '투자자',
          url: '/investors',
        },
        {
          content: '콘텐츠 크리에이터',
          url: '/creators',
        },
      ],
      learn: [
        {
          content: '튜토리얼',
          url: 'https://youtube.com/playlist?list=PLO6Ov0YONqdTdBV3nid3PWyVZD9V8YnJL',
        },
        { content: '활용 사례', url: 'https://blog.typed.biz/tag/typer/' },
        {
          content: 'Typed 투어 신청',
          url: 'https://calendly.com/business-canvas/typed-onboarding-kr',
        },
        { content: '고객센터', url: 'https://intercom.help/typed/ko/' },
      ],
    },
  },
  footer: {
    logoBoxTxt: '자료가 지식이 될 때까지.',
    mobileAppLinks: [
      {
        imgSrc: AppStoreImgSrc,
        url: 'https://apps.apple.com/kr/app/typed-save-and-collect-urls/id1606667024',
      },
      {
        imgSrc: GooglePlayImgSrc,
        url: 'https://play.google.com/store/apps/details?id=com.typed.application',
      },
    ],
    SNSLinks: [
      { imgSrc: FacebookLogoSrc, url: 'https://www.facebook.com/TypedKR' },
      {
        imgSrc: YoutubeLogoSrc,
        url: 'https://www.youtube.com/channel/UC5TE3eZk_t-OUl-BY-VTK_w/featured',
      },
      { imgSrc: InstagramLogoSrc, url: 'https://www.instagram.com/typed.do/' },
    ],
    linkItems: {
      Typed: { content: 'Typed', url: '' },
      Product: { content: '홈', url: '/' },
      Features: { content: '기능 소개', url: '/features' },
      Pricing: { content: '요금 안내', url: '/pricing' },
      Blog: { content: '블로그', url: 'https://typed.do/blog-kr' },
      Brochure: { content: '서비스 소개서', url: ServiceBrochureKRSrc },
      Privacy: { content: '개인정보처리방침', url: '/privacy' },
      Experience: { content: 'Use Cases', url: '' },
      Founders: { content: '창업자', url: '/founders' },
      Students: { content: '학생', url: '/students' },
      Investors: { content: '투자자', url: '/investors' },
      Creators: { content: '콘텐츠 크리에이터', url: '/creators' },
      Resources: { content: 'About Typed', url: '' },
      ['Typed Academy']: {
        content: '사용자 가이드',
        url: 'https://company.typed.do/academy-kr',
      },
      ['White Paper']: { content: '제품 철학', url: TypedWhitepaperSrc },
      Company: { content: '회사', url: '' },
      About: {
        content: '회사 정보',
        url: 'https://company.typed.do/',
      },
      Careers: {
        content: '인재 채용',
        url: 'https://business-canvas.career.greetinghr.com',
      },
      Notice: {
        content: '공지사항',
        url: 'https://company.typed.do/announcement',
      },
      ['Contact Us']: {
        content: 'Contact Us',
        url: 'https://form.typeform.com/to/pTWeehBF',
      },
    },
  },
  heroImageFixedTxts: {
    getStartedBtnTxt: '바로 시작하기',
  },
  ['How to Typed']: {
    title: 'How to Typed',
    bodyText1: '우리 팀에게 딱 맞는 활용법을 확인해보세요.',
    bodyText2: '나에게 딱 맞는 활용법을 확인해보세요.',
    items: {
      meetingNote: {
        imgSrc: meetingNoteSrc,
        txt: '미팅 노트',
        path: '',
      },
      businessPlan: {
        imgSrc: businessPlanSrc,
        txt: '사업계획서',
        path: '',
      },
      pitchDeck: {
        imgSrc: pitchDeckSrc,
        txt: '발표 자료',
        path: '',
      },
      researchPaper: {
        imgSrc: researchPaperSrc,
        txt: '레포트',
        path: 'https://www.youtube.com/watch?v=8gM06XrmapE&list=PLO6Ov0YONqdRxVSHElxSKUhcaAI-CYT7V&index=1',
      },
      groupProject: {
        imgSrc: groupProjectSrc,
        txt: '팀 프로젝트',
        path: 'https://www.youtube.com/watch?v=TrkCXsGjvoI&list=PLO6Ov0YONqdRxVSHElxSKUhcaAI-CYT7V&index=3',
      },
      studyGuide: {
        imgSrc: studyGuideSrc,
        txt: '노트 정리',
        path: 'https://www.youtube.com/watch?v=as5xBOj2e4o&list=PLO6Ov0YONqdRxVSHElxSKUhcaAI-CYT7V&index=5',
      },
      investmentMemo: {
        imgSrc: investmentMemoSrc,
        txt: '투심 보고서',
        path: '',
      },
      proposal: { imgSrc: proposalSrc, txt: '펀드 제안서', path: '' },
      marketResearch: {
        imgSrc: marketResearchSrc,
        txt: '시장 조사',
        path: '',
      },
      contentManagement: {
        imgSrc: contentManagementSrc,
        txt: '콘텐츠 기획',
        path: '',
      },
      articlesBlog: {
        imgSrc: articlesBlogSrc,
        txt: '블로그 / 뉴스레터',
        path: '',
      },
      secondBrain: {
        imgSrc: secondBrainSrc,
        txt: 'Second Brain',
        path: 'https://blog.typed.biz/typed-building-the-2nd-brain/',
      },
    },
  },
  startTyped: {
    headingTxt: '문서 협업의 비효율을 끝내다.',
    bodyTxt: '차원이 다른 문서 효율을 직접 경험해보세요.',
    getStarted: '바로 시작하기',
  },
  joinTypers: {
    heading3Txt: '151개국의 전 세계의 Typer들과 함께하고 있습니다.',
  },
  icps: {
    items: {
      founders: {
        title: '창업자',
        bodyText: '성공을 만드는 협업의 비결',
        imgSrc: FoundersICPSrc,
        path: '/founders',
      },
      students: {
        title: '학생',
        bodyText: '더 적은 시간으로 더 나은 결과를',
        imgSrc: StudentsICPSrc,
        path: '/students',
      },
      investors: {
        title: '투자자',
        bodyText: '이제 당신의 시간에도 투자하세요',
        imgSrc: InvestorsICPSrc,
        path: '/investors',
      },
      creators: {
        title: '콘텐츠 크리에이터',
        bodyText: '창조를 위한 모든 잠재력이 깨어나는 곳',
        imgSrc: CreatorsICPSrc,
        path: '/creators',
      },
    },
    ['See how others Typed']: '다양한 활용법을 확인하세요',
    ['See how you can Typed']: 'Typed가 필요한 순간',
    learnText: 'Learn',
  },
  landingPage: {
    heroImage: {
      heading1Text: '자료가 지식이 될 때까지.',
      body1Texts: [
        '자료 수집부터 문서 협업, 지식 관리까지',
        '하나의 허브에서 해결하세요.',
      ],
      mainImgSrc: LandingDocumentPageKRSrc,
      taskImgSrc: FoundersTaskKRSrc,
      resourceCard1ImgSrc: ResourceCard1KRSrc,
      resourceCard2ImgSrc: ResourceCard2KRSrc,
      greenCursorSrc: CursorGreenSrc,
      purpleCursorSrc: CursorPurpleSrc,
    },
    joinTypersImgSrcs: [
      GoogleLogoSrc,
      SNULogoSrc,
      SendBirdLogoSrc,
      SamsungLogoSrc,
      KakaoVenturesLogoSrc,
    ],
    testimonial: {
      mainImgSrc: TimPaoliniSrc,
      logoImgSrc: GoogleLogoSrc,
      comments: [
        '“구글 문서와 드라이브를 이미 사용하고 있다면,',
        'Typed를 썼을 때 놀라운 시너지를 경험할 수 있을 거에요.”',
      ],
      name: 'Tim Paolini',
      job: '구글 APAC GWS 총괄',
    },
    featureIntroductions: [
      {
        headingTexts: ['모든 작업을', '하나의 공간에서'],
        bodyTexts: [
          '문서를 작성하고, 자료를 수집·관리하고,',
          '협업하는 것까지',
          '모든 것을 하나의 공간에서 경험해보세요.',
        ],
        mainMediaSrc: BringEverythingTogetherSrc,
        subImgSrc: BringEverythingCursorSrc,
      },
      {
        headingTexts: ['자료 관리는', 'Typed에게 맡기세요'],
        bodyTexts: [
          '복잡한 자료 관리는 Typed가 알아서 할게요.',
          '정보를 종합해 새로운 지식을 창출하는데',
          '오롯이 집중하고 몰입해보세요.',
        ],
        mainMediaSrc: LetTheToolDoTheWorkSrc,
      },
      {
        headingTexts: ['모든 자료를', '200% 활용하세요'],
        bodyTexts: [
          '더 이상 내 자료를 찾아 헤매지 마세요.',
          '나의 모든 정보를 한 눈에 확인하고',
          '새로운 인사이트를 얻어 활용해보세요.',
        ],
        mainMediaSrc: BeOnTopOfYourGameSrc,
      },
    ],
    afterTyped: {
      heading2Txt: '이제 폭발적인 생산성을 경험해보세요',
      subHeading2Txt: '준비되셨나요?',
      beforeTypedTxt: 'Before Typed',
      beforeTypedSrc: BeforeTypedImgSrc,
      afterTypedTxt: 'After Typed',
      afterTypedSrc: AfterTypedImgSrc,
    },
    recommendMobileApp: {
      mobileImgSrc: MobileExampleImgSrc,
      headingTexts: ['모바일에서도', '자료를 놓치지 마세요.'],
      bodyTexts: ['언제나, 어디서나 Typed 모바일 앱으로', '자료를 수집하세요.'],
      appStoreUrl:
        'https://apps.apple.com/kr/app/typed-save-and-collect-urls/id1606667024',
      appStoreSrc: AppStoreSrc,
      googlePlayUrl:
        'https://play.google.com/store/apps/details?id=com.typed.application',
      googlePlaySrc: GooglePlaySrc,
    },
  },
  featuresPage: {
    heroImage: {
      heading3Text: 'Features',
      heading1Text: '자료를 활용하는 더 나은 방법',
      body1Texts: [
        '넘쳐나는 정보들을 어떻게 활용할지 막막하기만 하셨나요?',
        '당신을 탓하지 마세요. 기술과 시스템이 해결할 때입니다.',
      ],
      mainImgSrc: FounderDocumentKRPageSrc,
      taskImgSrc: FoundersTaskSrc,
      subImgSrc: FounderProjectKRImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['하나의 공간에서', '모든 것을 해결하세요'],
        bodyTexts: [
          '자료를 수집하고 관리하는 것부터 자료를 조회하며 문서를 작성하고 할 일을 정리하는 것까지 심리스한 작업과 협업을 위한 최적의 공간을 경험해보세요.',
        ],
        subtitle: '',
        mainMediaSrc: CreateBetterSrc,
      },
      {
        headingTexts: ['관리를 위한 관리는', '이제 그만'],
        bodyTexts: [
          "정보화 시대라 불리는 오늘날, 우리는 더 이상 '보관용'으로 만들어진 폴더 시스템에 기대어 파일을 관리하긴 어렵습니다.",
          "다양한 포맷의 자료를 '지식'으로 만들고 다양한 포맷의 문서로 '업무'를 수행하며 Typed에서는 나와 우리 팀에게 중요한 정보들을 가장 효과적으로 관리할 수 있습니다.",
        ],
        subtitle: '',
        mainMediaSrc: OrganizeLessSrc,
      },
      {
        headingTexts: ['폴더 속을 헤매지 마세요,', '자료의 관계를 한 눈에'],
        bodyTexts: [
          '폴더 속의 폴더 속에 숨어있는 그 자료, 폴더 시스템에서는 볼 수 없었던 자료 간 관계를 이제는 한 눈에 확인해보세요. Typed의 지식 네트워크는 사용자 행동을 기반으로 맥락과 관계를 파악하여, 모든 지식을 자동으로 연결합니다.',
        ],
        subtitle:
          '지식 네트워크로 정보와 업무의 흐름을 한 눈에 파악하고, 개인과 팀의 잠재력을 이끌어내세요.',
        mainMediaSrc: ConnectAllSrc,
      },
      {
        headingTexts: ['빠르고 쉽게, 어디서나', '수집하세요'],
        bodyTexts: [
          '소중한 자료가 휘발되기 전에 웹 클리퍼와 모바일 앱으로 손쉽게 수집해보세요. 워드, PPT, 엑셀, 한글, 이미지, 그리고 웹 자료(URL)까지 어떤 포맷이든 수집하여 활용할 수 있습니다.',
        ],
        subtitle: '',
        mainMediaSrc: SaveAnythingSrc,
      },
    ],
  },
  foundersPage: {
    heroImage: {
      heading3Text: '위대한 창업자인 당신을 위해',
      heading1Text: '성공을 만드는 협업의 비결',
      body1Texts: ['단 한번의 협업도 헛되지 않도록.'],
      mainImgSrc: FounderDocumentKRPageSrc,
      taskImgSrc: FoundersTaskKRSrc,
      subImgSrc: FounderProjectKRImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['모든 지식이', '집대성되는 곳'],
        bodyTexts: [
          '오늘날 우리는 20%의 업무시간을 내외부 자료를 찾아헤매이는데 낭비합니다. 5명 중 1명은 사실상 실제 부가가치를 내지 못하는 것이죠.',
          'Typed는 컨텍스트에 따라 필요한 정보를 가장 쉽게 찾을 수 있는 지식의 허브입니다.',
        ],
        subtitle: '',
        mainMediaSrc: SingleSourceOfTruthSrc,
      },
      {
        headingTexts: ['정보와 협업', '가시성의 극대화'],
        bodyTexts: [
          '진행 중인 프로젝트에는 어떤 정보가 흐르고 있는지, 누가 어떤 자료를 활용하고 있는지, 완료된 보고서에는 어떤 과정이 있었는지.',
          'Typed는 그간 볼 수 없었던 사각지대까지 완벽하게 공유되어 협업 효율성을 극대화합니다.',
        ],
        subtitle: '',
        mainMediaSrc: MaximizeBandwidthSrc,
      },
      {
        headingTexts: ['목표를 중심으로', '협업을 진척시켜보세요'],
        bodyTexts: [
          'Typed의 목표 지향적 지식관리와 협업 시스템은 실제로 일이 진척되게 만들어 결과로 이끕니다. 몇 달 후 무의미해질 폴더 관리에서 이제 벗어나보세요.',
        ],
        subtitle: '프로세스를 넘어서는 프로그레스의 힘.',
        mainMediaSrc: ProgressOverProcessSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: TaeyangLeeSrc,
        logoImgSrc: TossLogoSrc,
        comments: [
          'Typed는 잘 정돈된, 커다란 책상 같아요. 자료를 쉽게 정리하고 확인하며 문서 작성에만 집중할 수 있어서 거의 모든 문서 작업을 Typed로 하고 있습니다.',
          '업무 특성상 많은 자료와 글을 다루는 모든 분들께 Typed를 추천합니다.',
        ],
        name: '이태양',
        job: 'Toss 공동창업 / 블루포인트파트너스 수석심사역',
      },
      {
        mainImgSrc: SeheeChunSrc,
        logoImgSrc: GrowAndBetterLogoSrc,
        comments: [
          '같은 구글 닥스, 스프레드시트, 슬라이드도 Typed를 활용하면 생산성을 훨씬 극대화할 수 있습니다. 익숙한 구글 워크스페이스를 기반으로 한다는 점과 분산된 자료들이 심리스하게 연결되어 여러 탭을 열어놓을 필요 없이 빠르게 문서 작성이 가능하다는 점이 Typed의 강점입니다.',
        ],
        name: '천세희 대표',
        job: '더자람컴퍼니 CEO',
      },
    ],
    joinTypersImgSrcs: [
      SendBirdLogoSrc,
      ChannelTalkLogoSrc,
      SamsungLogoSrc,
      TossLogoSrc,
    ],
  },
  studentsPage: {
    heroImage: {
      heading3Text: '스마트한 학생인 당신을 위해',
      heading1Text: '더 적은 시간으로 더 나은 결과를',
      body1Texts: ['누구보다 빠르게, 남들과는 다르게.'],
      mainImgSrc: StudentsDocumentPageKRSrc,
      taskImgSrc: StudentsTaskKRSrc,
      subImgSrc: StudentProjectKRImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['결과물의 질을 높이는', '확실한 자료 수집'],
        bodyTexts: [
          '전 세계에서 가장 빠른 웹 클리퍼로 1초만에 웹 자료를 수집하고, 워드, PDF, 한글 파일, 화면 캡쳐본까지 다양한 포맷의 자료를 바로 문서에 활용하세요.',
          '자료의 검색보다 자료의 활용이 더 중요해지는 시대, Typed와 함께 소중한 자료들을 양질의 지식으로 만들어보세요.',
        ],
        subtitle: '단 한 번의 클릭으로 모든 정보를 내 것으로 만드세요.',
        mainMediaSrc: ResearchFasterSrc,
      },
      {
        headingTexts: ['똑똑한 문서작성을 위한', '최적의 공간'],
        bodyTexts: [
          'Typed에선 다양한 포맷의 자료들을 조회하는 것부터 문서를 작성하는 것까지 한 화면에서 가능하니까요. 수많은 탭과 앱들 vs 단 하나의 Typed. 여러분의 선택은 무엇인가요?',
        ],
        subtitle: '더 이상 수많은 탭 사이를 이동하며 집중력을 소모하지 마세요.',
        mainMediaSrc: WriteSmarterSrc,
      },
      {
        headingTexts: ['A+을 받을 수밖에 없는', '팀플의 비밀'],
        bodyTexts: [
          '메신저로 자료를 요청하고 최종본, 최최종본을 갱신하고 있나요? 정보의 파편화와 불투명성은 팀 프로젝트를 비효율로 이끕니다.',
          '팀 프로젝트의 생명은 원활한 소통과 명확한 역할 분담입니다. Typed는 한 공간에서 공동작업부터 역할 분담, 할 일 관리, 그리고 완벽한 공유까지. 무임승차 없는 성공적인 팀 프로젝트를 가능케 하죠.',
        ],
        subtitle: '',
        mainMediaSrc: StressFreeSrc,
        subImgSrc: StressFreeCursorSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: EunjiLeeSrc,
        logoImgSrc: StanfordLogoSrc,
        comments: [
          'Typed를 통해 합리성과 효율성 모두를 충족시키고 있어요. 번거로운 탭 전환 없이 한 눈에 자료와 문서를 볼 수 있어 온전히 생각 정리에만 집중할 수 있어요. 학교 공부부터 인턴십, 그리고 댜앙한 프로젝트까지 Typed를 활용하고 있습니다.',
        ],
        name: '이은지',
        job: '스탠포드 대학교',
      },
      {
        mainImgSrc: SeohyunJeongSrc,
        logoImgSrc: YonseiLogoSrc,
        comments: [
          '레포트 작성이나 시험 공부에도 잘 쓰고 있는데 특히 팀플 할 때도 Typed의 덕을 보고 있어요. 각자 조사해온 자료들부터 팀이 다같이 작성하는 회의록이나 발표자료까지 한 공간에서 함께 관리할 수 있다는 점이 매력적이에요.',
        ],
        name: '정서현',
        job: '연세대학교 / 연고티비 크루',
      },
    ],
    joinTypersImgSrcs: [
      SNULogoSrc,
      YonseiLogoSrc,
      KaistLogoSrc,
      StanfordLogoSrc,
      YaleLogoSrc,
    ],
  },
  investorsPage: {
    heroImage: {
      heading3Text: '효율적인 투자자인 당신을 위해',
      heading1Text: '이제 당신의 시간에도 투자하세요.',
      body1Texts: [
        '비즈니스의 미래를 만드는 당신의 시간만큼 소중한 자산은 없습니다.',
      ],
      mainImgSrc: InvestorsDocumentPageSrc,
      taskImgSrc: InvestorsTaskSrc,
      subImgSrc: InvestorProjectImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['효율적인 리서치로', '차원이 다른 인사이트를'],
        bodyTexts: [
          '뉴스레터부터 웹 기사, PDF 레포트까지 포맷을 막론하고 소중한 자료는 전 세계에서 가장 빠른 Typed의 웹 클리퍼와 모바일 앱으로 언제 어디서나 수집하세요.',
        ],
        subtitle:
          '산업의 전망을 꿰뚫어보는 통찰력의 핵심은 리서치의 퀄리티입니다.',
        mainMediaSrc: DiligentEveryStepSrc,
      },
      {
        headingTexts: ['관리가 필요 없는', '지식관리의 신세계'],
        bodyTexts: [
          '필요한 자료를 문서 중심으로 수집하고, 그저 활용하세요. 지식의 재활용은 Typed에게 맡기세요. 한번 활용된 정보는 입체적인 지식으로서 언제든 능동적으로 활용할 수 있어요.',
        ],
        subtitle:
          '끝없는 폴더 숲과 이메일 스레드를 헤매며 소중한 시간을 낭비하지 마세요.',
        mainMediaSrc: DontMissAnyDetailsSrc,
      },
      {
        headingTexts: ['예비 유니콘을 찾고,', '함께 성장하세요.'],
        bodyTexts: [
          '회의록부터 투심보고서까지 관련 정보를 200% 활용하여 가장 효율적으로 문서 업무를 할 수 있어요.',
        ],
        subtitle:
          '투자 대상인 스타트업과 관련된 모든 정보를 하나의 컨텍스트에서 관리하세요.',
        mainMediaSrc: ScaleYourPartnersSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: ShinaJeongSrc,
        logoImgSrc: KakaoVenturesLogoSrc,
        comments: [
          '흩어진 자료와 정보들을 활용할 때 Typed의 백링크가 참 유용해요.  Typed가 추구하는 지식 네트워크는 문서 작성에 불필요하게 낭비되는 시간과 에너지를 절약하고 최적의 생산성을 이끌어낼 것입니다.',
        ],
        name: '정신아 대표',
        job: '카카오벤처스 대표이사',
      },
      {
        mainImgSrc: SangminHongSrc,
        logoImgSrc: NextransLogoSrc,
        comments: [
          '다양한 분야에 대한 리서치를 바탕으로 문서를 작성하는 모든 작업에서 생산성과 효율성을 실감하고 있습니다. 사용하기 쉽고 편할 뿐 아니라 친숙하기 때문에 지금까지 쭉 잘 써오고 있어요. 앞으로 만들어갈 변화가 더욱 기대됩니다.',
        ],
        name: '홍상민 대표',
        job: '넥스트랜스 대표',
      },
    ],
    joinTypersImgSrcs: [
      DunamuLogoSrc,
      KakaoVenturesLogoSrc,
      NextransLogoSrc,
      BluepointLogoSrc,
      SopoongLogoSrc,
    ],
  },
  creatorsPage: {
    heroImage: {
      heading3Text: '창의적인 크리에이터인 당신을 위해',
      heading1Text: '창조를 위한 모든 잠재력이 깨어나는 곳',
      body1Texts: ['극한의 몰입으로 영감을 끌어내세요.'],
      mainImgSrc: CreatorsDocumentPageKRSrc,
      taskImgSrc: CreatorsTaskKRSrc,
      subImgSrc: CreatorProjectKRImgSrc,
    },
    featureIntroductions: [
      {
        headingTexts: ['모든 트렌드를', '빠르게 파악하는 비결'],
        bodyTexts: [
          '웹 기사부터 이미지, 유튜브 영상까지, 트렌드의 최전선에 머물기 위해 수많은 정보를 섭렵하는 당신.',
          '이제 Typed의 웹 클리퍼와 모바일 앱을 통해 당신의 콘텐츠의 영감을 줄 모든 정보를 단 한 번의 터치로 수집하고 관리하세요.',
        ],
        subtitle: '',
        mainMediaSrc: FindYourEdgeSrc,
      },
      {
        headingTexts: ['최고의 콘텐츠를 위한', '무한한 몰입'],
        bodyTexts: [
          '웹 브라우저 내 수많은 탭, 바탕화면 속 떠 있는 수많은 앱. 당신의 집중을 방해했던 모든 것에서 벗어나세요.',
          '콘텐츠 작성에 필요한 모든 자료를 한 화면에 모아놓고 언제든 활용하세요. 이제 수집된 정보는 당신의 영감을 만나 새로운 지식으로 탄생할 때입니다.',
        ],
        subtitle: '',
        mainMediaSrc: JustFocusOnContentSrc,
      },
      {
        headingTexts: ['과거의 나로부터 이어지는', '무한한 영감의 원천'],
        bodyTexts: [
          '당신의 Second Brain, Typed의 지식 네트워크로 당신의 모든 아이디어와 지식의 관계성을 시각적으로 볼 수 있어요.',
        ],
        subtitle: '어제의 나로부터 오늘의 새로운 영감의 원천을 얻으세요.',
        mainMediaSrc: BeYourOwnInspirationSrc,
      },
    ],
    testimonials: [
      {
        mainImgSrc: DaeyeonJinSrc,
        logoImgSrc: '',
        comments: [
          '가장 마음에 드는 기능은 하이라이트 기능입니다. 웹 자료와 PDF 자료에서 하이라이팅 해둔 내용은 사이드바에서 바로 볼 수 있기 때문에 필요할 때마다 핵심 내용을 한눈에 파악하며 문서에 반영할 수 있습니다.',
          'Typed와 함께 손쉽게 양질의 지식들을 만들어갈 수 있을 날을 기대합니다.',
        ],
        name: '진대연',
        job: '생산성 전문 뉴스레터 <당근메일> 설립자 / mmhmm 마케팅 및 경영전략',
      },
      {
        mainImgSrc: JosephMavericksSrc,
        logoImgSrc: '',
        comments: [
          'Typed를 활용하면서 제 시간을 측정해 본 결과는 놀라웠어요. 자료 조사에 드는 시간의 75%를 절약하게 되었다는 것입니다! 제가 해야 하는 일이라고는 참고자료나 기사들을 읽으며 그저 Typed 웹 클리퍼를 클릭하는 게 다였는데 말이죠. 이렇게 절약한 주 3시간으로 영상 기획과 글쓰기를 할 수 있게 되었습니다.',
        ],
        name: 'Joseph Mavericks',
        job: 'Medium 블로거',
      },
    ],
    joinTypersImgSrcs: [JosephImgSrc, RedHeadAcademicImgSrc, Q2HanImgSrc],
  },
  pricingPage: {
    heroLayout: {
      heading1Txt: '신중히 고민하고, 과감하게 선택하세요.',
      ['billed monthly']: '월 결제',
      ['billed yearly']: '연 결제',
      save: 'Save',
      savePercentage: '20%',
      freePlan: {
        title: '체험판',
        titleDesc: ['개인과 초기 팀을 위한', '무료 요금제입니다.'],
        price: () => '$0',
        priceDesc: () => ['편집자 당'],
        btnText: '무료 체험하기',
        btnPath: '/signup',
        detailTitle: '무료 지원 기능',
        detailContents: [
          { isActive: true, content: '1개 문서' },
          { isActive: true, content: '편집자 당 1GB 참고자료 저장공간' },
          { isActive: true, content: 'Typed 확장 프로그램' },
          { isActive: true, content: 'Typed 모바일 앱' },
        ],
      },
      proPlan: {
        ['best value']: 'Best value!',
        title: '프로',
        titleDesc: [
          '체계적인 지식관리가 필요한',
          '개인과 팀을 위한 프로 요금제입니다.',
        ],
        price: (isYearly) => (isYearly ? '$8' : '$10'),
        priceDesc: (isYearly) => [
          `${isYearly ? '연' : '월'} 결제 기준`,
          '편집자 당',
        ],
        btnText: '시작하기',
        btnPath: '/signup',
        detailTitle: '프로를 위한 지원 기능',
        detailContents: [
          { isActive: true, content: '무제한 문서' },
          { isActive: true, content: '편집자 당 10GB 참고자료 저장공간' },
          { isActive: true, content: '신규 기능 우선 체험' },
        ],
      },
      enterprisePlan: {
        title: '기업',
        titleDesc: ['기업을 위한 기능과', '맞춤 고객지원이 제공됩니다.'],
        price: () => '지금 연락주세요!',
        btnText: '영업팀에 문의하기',
        btnPath: 'https://calendly.com/business-canvas/letmeintroducetyped',
        detailTitle: '기업을 위한 지원 기능',
        detailContents: [
          { isActive: true, content: '무제한 참고자료 저장공간' },
          { isActive: true, content: '고도화된 지식 네트워크 제공' },
          { isActive: true, content: '전담 지원 매니저 지정' },
          { isActive: true, content: '맞춤형 상담 및 계약 진행' },
        ],
      },
      compareBtnTxt: '플랜 비교하기',
      ['are you a student']: '학생이신가요?',
      ['check FAQ']: [
        'Typed는 학생 1년 무료 라이센스를 제공합니다.',
        '자세한 내용은 하단의 Q&A를 확인해주세요.',
      ],
      joinTypersImgSrcs: [
        GoogleLogoSrc,
        SNULogoSrc,
        SendBirdLogoSrc,
        SamsungLogoSrc,
        IterativeLogoSrc,
      ],
      pricingPlanTable: {
        freePlan: {
          title: '체험판',
          btnTxt: '무료 체험 하기',
          btnPath: '/signup',
        },
        proPlan: {
          title: '프로',
          btnTxt: '시작하기',
          btnPath: '/signup',
        },
        enterprisePlan: {
          title: '기업',
          btnTxt: '영업팀에 문의하기',
          btnPath: 'https://form.typeform.com/to/pTWeehBF',
        },
        items: [
          {
            name: '사용량',
            imgSrc: UsageImgSrc,
            details: [
              {
                name: '편집자',
                free: '무제한',
                pro: '무제한',
                enterprise: '무제한',
              },
              {
                name: '뷰어',
                free: '무제한',
                pro: '무제한',
                enterprise: '무제한',
              },
              {
                name: '문서',
                free: '1',
                pro: '무제한',
                enterprise: '무제한',
              },
              {
                name: '참고자료 저장공간',
                free: '스페이스 당 1GB',
                pro: '편집자 당 10GB',
                enterprise: '무제한',
              },
              {
                name: '공유 지원',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: '협업',
            imgSrc: CollaborationImgSrc,
            details: [
              {
                name: '실시간 공동 작업',
                free: undefined,
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '협업 스페이스',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '뷰어 초대',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '링크 공유',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: '연동',
            imgSrc: IntegrationImgSrc,
            details: [
              {
                name: 'Google Workspace',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Drive',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Tasks',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: '기능',
            imgSrc: FeaturesImgSrc,
            details: [
              {
                name: 'Typed Web',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Typed Mobile',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '라이브러리',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '하이라이트',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '인박스',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '확장 프로그램',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '백링크',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '검색',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '지식 네트워크',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '추천 시스템',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '고도화된 지식 네트워크',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: '체계',
            imgSrc: OrganizeImgSrc,
            details: [
              {
                name: '스페이스',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '팀',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '프로젝트',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: '참고자료 형식',
            imgSrc: ResourcesImgSrc,
            details: [
              {
                name: '웹 참고자료 (뉴스, 블로그, 유튜브 등)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'PDF',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '이미지',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Doc (.doc, .docs)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'PPT (.ppt, .pptx)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Excel (.xlxs)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '메모',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Docs',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Slides',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Google Sheets',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
            ],
          },
          {
            name: '지원 & 보안',
            imgSrc: AdminImgSrc,
            details: [
              {
                name: '헬프센터',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '실시간 CS 지원',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: 'Single Sign On (SSO/SAML)',
                free: '@check@',
                pro: '@check@',
                enterprise: '@check@',
              },
              {
                name: '우선 지원 서비스',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
              {
                name: '오프라인 온보딩',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
              {
                name: '전담 지원 매니저 지정',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
              {
                name: '맞춤형 상담 및 송장 발송',
                free: undefined,
                pro: undefined,
                enterprise: '@check@',
              },
            ],
          },
        ],
      },
    },
    faqs: {
      title: 'FAQs',
      ['look for something else']: '더 많은 정보가 필요하신가요?',
      ['see more']: '헬프센터에서 확인해주세요!',
      ['see more url']:
        'https://academy.typed.do/faq/v/faq-1/pricing-and-payment/typed',
      contents: [
        {
          question: '체험판과 유료 플랜은 어떻게 다른가요?',
          answers: [
            '체험판은 각 스페이스별로, 초대 인원 제한 없이 문서 1개까지 생성할 수 있습니다.',
            '프로 플랜은 각 스페이스에서 문서가 1개를 초과하는 경우 적용되며, 문서 생성 무제한, 멤버별 10GB의 참고자료 용량을 지원합니다.',
          ],
        },
        {
          question: '체험판의 문서 제한(1개)을 초과하면 어떻게 되나요?',
          answers: [
            '홈 화면에서 문서 조회, 인박스에서 참고 자료 수집 및 조회가 가능하지만, 문서 페이지 진입은 제한됩니다.',
            `좌측 상단 '설정 & 멤버'의 '요금제 & 청구'에서 플랜 업그레이드가 가능합니다.`,
          ],
        },
        {
          question: '체험판에서도 협업이 가능한가요?',
          answers: [
            '체험판에서도 협업이 가능합니다.',
            '스페이스를 최초 생성한 유저는 스페이스의 오너가 됨, 오너는 초대하는 멤버를 뷰어 혹은 편집자로 권한을 부여할 수 있습니다. 초대받은 멤버는 초대 메일을 받고, 수락하면 스페이스에 참여하게 됩니다.',
          ],
        },
        {
          question: '팀(기업) 요금제는 어떻게 책정되나요?',
          answers: ['팀(기업) 요금제는 sales@typed.do로 문의 부탁드립니다.'],
        },
        {
          question: 'Typed에서 여러 스페이스에 소속될 수 있나요?',
          answers: [
            '스페이스는 무제한으로 생성할 수 있습니다. 그리고 한 계정이 여러 개의 스페이스에 속할 수 있습니다.',
            '단, 스페이스는 독립된 공간으로 스페이스 간 자료 공유는 불가합니다. 그리고 스페이스에 속한 문서가 1개를 초과할 경우 유료 플랜을 이용하셔야 하오니, 이 점 유의 부탁드립니다.',
          ],
        },
        {
          question: '결제는 어떤 방식으로 처리되나요?',
          answers: [
            'Pro Plan 결제는 연 단위 결제 기준 편집자 당 $8/월, 월 단위 결제 기준 편집자 당 $10/월입니다.',
            '예) 5명인 스페이스를 월 단위 결제로 이용할 경우, 스페이스 소유자가 등록한 카드에서 매월 $50가 결제됩니다. 단, 뷰어는 과금 대상에서 제외됩니다.',
          ],
        },
        {
          question: 'Typed는 학생 할인을 제공하나요?',
          answers: [
            'Typed는 프로 플랜 혜택을 학생에게 1년간 제공합니다.',
            "가입 이후 좌측 상단 '설정 & 멤버'의 '요금제 & 청구'에서 학생 무료 플랜을 신청할 수 있습니다.",
          ],
        },
      ],
    },
  },
}

export { englishWebsiteText, koreanWebsiteText }
