import { ILoggingClient } from '@/src/integrations/logging/client'

export default abstract class TypedEvent {
  constructor(private readonly clients: ILoggingClient[]) {}

  sendEvent(...[eventName, property]: Parameters<ILoggingClient['sendEvent']>) {
    this.clients.forEach((client) => {
      client.sendEvent(eventName, {
        ...property,
        Email: null,
        uid: null,
        spaceId: null,
      })
    })
  }
}
