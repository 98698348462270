type Class = { new (...args: any[]): any }

const methodBlacklistsMap: Record<string, boolean> = {
  constructor: true,
}

const autoBind = <T>(cls: T & Class): T =>
  new Proxy(cls, {
    construct(cls, args) {
      const instance = new cls(...args)
      Object.getOwnPropertyNames(cls.prototype).forEach((methodName) => {
        const isGetter = Boolean(
          Object.getOwnPropertyDescriptor(cls.prototype, methodName)?.get,
        )

        if (
          !isGetter &&
          !methodBlacklistsMap[methodName] &&
          typeof instance[methodName] === 'function'
        ) {
          instance[methodName] = instance[methodName].bind(instance)
        }
      })

      return instance
    },
  })

export default autoBind
