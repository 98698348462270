export const loadScript = (src: string, target = document.body) =>
  new Promise<void>((resolve, reject) => {
    const scriptElement = document.createElement('script')
    scriptElement.type = 'text/javascript'
    scriptElement.async = true
    scriptElement.src = src
    scriptElement.onload = () => resolve()
    scriptElement.onerror = () => {
      target.removeChild(scriptElement)
      reject()
    }

    target.appendChild(scriptElement)
  })

export const appendNoScript = (
  element: HTMLElement,
  target = document.body,
) => {
  const noscriptElement = document.createElement('noscript')
  noscriptElement.appendChild(element)
  target.appendChild(noscriptElement)
}

export const loadScriptWithRetry = async (
  src: string,
  options: { target?: HTMLElement; retryCount?: number } = {},
) => {
  for (let tryCount = 0; tryCount < (options.retryCount ?? 3); tryCount += 1) {
    try {
      await loadScript(src, options.target)
      break
    } catch {
      console.error(`Failed to load script ${src}, tryCount: ${tryCount}`)
    }
  }
}
