export enum MediaQuery {
  MOBILE = '@media (max-width: 760px)',
  TABLET = '@media (max-width: 1024px) and (min-width: 761px)',
  PC = '@media (min-width: 1025px)',
  NON_PC = '@media (max-width: 1024px)',
  NON_MOBILE = '@media (min-width: 761px)',
}

export enum Selectors {
  ROOT = '__next',
}
