import {
  englishTermsAndPolicyText,
  koreanTermsAndPolicyText,
} from './translate.termsAndPolicy'
import { englishWebsiteText, koreanWebsiteText } from './translate.website'

const SUPPORTED_LANGUAGES = ['ko', 'en']
export type SUPPORTED_LANGUAGES = 'ko' | 'en'
const DEFAULT_LANGUAGE = 'en'

// https://stackoverflow.com/a/25603630
export const getBrowserLanguage = () => {
  if (typeof window === 'undefined') return 'en'
  if (typeof window.navigator === 'undefined') return 'en'
  return window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language
}

export const getLanguage = () => {
  let language = getBrowserLanguage()?.substring(0, 2)

  if (!SUPPORTED_LANGUAGES.includes(language)) {
    language = DEFAULT_LANGUAGE
  }

  return language as SUPPORTED_LANGUAGES
}

export const TEXTS_WITH_LOCALE = {
  en: {
    website: {
      ...englishWebsiteText,
    },
    termsAndPolicy: {
      ...englishTermsAndPolicyText,
    },
  },
  ko: {
    website: {
      ...koreanWebsiteText,
    },
    termsAndPolicy: {
      ...koreanTermsAndPolicyText,
    },
  },
}
