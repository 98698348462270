import Head from 'next/head'

interface Props {
  title: string
  description: string
}

export default function TitleAndDesc({ title, description }: Props) {
  return (
    <Head>
      <title>{title}</title>
      <meta key='description' name='description' content={description} />
      <meta key='og-title' property='og:title' content={title} />
      <meta
        key='og-description'
        property='og:description'
        content={description}
      />
      <meta key='twitter-title' name='twitter:title' content={title} />
      <meta
        key='twitter-description'
        name='twitter:description'
        content={description}
      />
    </Head>
  )
}
