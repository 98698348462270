import { AxiosInstance } from 'axios'
import { ILoggingClient } from '.'

class TypedLogger {
  private id: string | null
  private properties: any

  constructor(readonly loggingAxios: AxiosInstance) {
    this.id = null
  }

  setUserId(id: string | null): void {
    this.id = id
  }

  logEvent(event: string, data?: any) {
    if (
      !process.env.apiServerURL?.startsWith('https://local.business-canvas.xyz')
    ) {
      this.loggingAxios({
        method: 'post',
        url: '/logging/',
        data: {
          eventName: event,
          id: this.id,
          ...this.properties,
          ...data,
        },
      })
    }
  }
  setUserProperties(properties: any) {
    this.properties = properties
  }
}

export class TypedLoggingClient implements ILoggingClient {
  private client: TypedLogger
  constructor(loggingAxios: AxiosInstance) {
    this.client = new TypedLogger(loggingAxios)
  }

  setUserProperty(id: string | null, email?: string) {
    this.client.setUserId(id)
    this.client.setUserProperties({ Email: email })
  }

  sendEvent(eventName: string, property?: object) {
    this.client.logEvent(eventName, { ...property })
  }
}
