import axios, { AxiosInstance } from 'axios'
import { ITypedAxiosGroup } from '@/src/utils/axios'

export default class TypedAxiosGroup implements ITypedAxiosGroup {
  globalAxios: AxiosInstance
  loggingAxios: AxiosInstance
  constructor() {
    const defaultConfig = {
      headers: {
        'content-type': 'application/json',
      },
    }

    this.loggingAxios = axios.create({
      ...defaultConfig,
      baseURL: process.env.apiServerURL,
    })

    this.globalAxios = axios.create(defaultConfig)
  }
}
