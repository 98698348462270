import React, { ReactElement, useEffect } from 'react'
import { HOMEPAGE_EVENT } from '@/src/integrations/logging/enum'
import appSettings from '@/src/setup'

const HomePageWrapper = ({
  children,
  eventFrom,
}: {
  children: React.ReactNode
  eventFrom: HOMEPAGE_EVENT
}) => {
  useEffect(() => {
    appSettings.eventLoggers?.homepageEvent?.homepageViewed(eventFrom)
  }, [eventFrom])
  return <>{children}</>
}

const wrapWithEventLogger = (page: ReactElement, pageName: string = '') => {
  return pageName in HOMEPAGE_EVENT ? (
    <HomePageWrapper
      eventFrom={HOMEPAGE_EVENT[pageName as keyof typeof HOMEPAGE_EVENT]}
    >
      {page}
    </HomePageWrapper>
  ) : (
    <>{page}</>
  )
}

export { wrapWithEventLogger }
