// event loggers
import { ITypedEventLoggers } from '@/src/integrations/logging/event/typedEventLoggers'

import TypedEventLoggers from '@/src/setup/eventLoggersSetup'
import autoBind from '@/src/utils/autoBind'
import { ITypedAxiosGroup } from '@/src/utils/axios'
import { googleIntegrationSetup } from '@/src/setup/googleIntegrationsSetup'
import TypedAxiosGroup from '@/src/setup/axiosSetup'
import { quantcastSetup } from '@/src/setup/quantcastSetup'

export const SCRIPT_KEYS_TO_IGNORE: Partial<
  Record<keyof IScriptsLoadingState, true | undefined>
> = {}

export interface IScriptsLoadingState {
  // user trackers
  isAmplitudeLoaded: boolean

  // etc
  isGoogleAnalyticsLoaded: boolean
  isGoogleAdsLoaded: boolean
  isGoogleAdsConversionEventLoaded: boolean
  isGoogleTagManagerLoaded: boolean
  isQuantcastLoaded: boolean
}

export class AppSettings {
  _eventLoggers: ITypedEventLoggers | null
  axiosGroup: ITypedAxiosGroup | null
  onLoad?: () => void

  readonly scriptsLoadingState = new Proxy(
    {
      // user trackers
      isAmplitudeLoaded: false,

      // etc
      isGoogleAnalyticsLoaded: false,
      isGoogleAdsLoaded: false,
      isGoogleAdsConversionEventLoaded: false,
      isGoogleTagManagerLoaded: false,
      isQuantcastLoaded: false,
    },
    {
      set: (obj, key: keyof IScriptsLoadingState, value) => {
        obj[key] = value

        if (!this.wasOnLoadCalled && this.onLoad) {
          this.onLoad()
          this.wasOnLoadCalled = true
        }

        if (
          !this.wasUserInfoSet &&
          this.isScriptsLoaded(this.USER_TRACKER_KEYS)
        ) {
          this.wasUserInfoSet = true
        }

        return true
      },
    },
  )

  private USER_TRACKER_KEYS: (keyof IScriptsLoadingState)[] = [
    'isAmplitudeLoaded',
  ]

  private wasOnLoadCalled = false
  private wasUserInfoSet = false

  constructor() {
    this._eventLoggers = null
    this.axiosGroup = null
  }

  initializeAfterMount() {
    // No dependencies
    this.axiosGroup = new TypedAxiosGroup()
    googleIntegrationSetup(this.scriptsLoadingState)
    quantcastSetup(this.scriptsLoadingState)

    // Has dependencies
    this.eventLoggers = new TypedEventLoggers(
      this.axiosGroup,
      this.scriptsLoadingState,
    )
  }

  private isScriptsLoaded(keys: (keyof IScriptsLoadingState)[]) {
    return keys
      .filter((key) => !SCRIPT_KEYS_TO_IGNORE[key])
      .every((key) => this.scriptsLoadingState[key])
  }

  set eventLoggers(logger: ITypedEventLoggers | null) {
    this._eventLoggers = logger
  }

  get eventLoggers() {
    return this._eventLoggers
  }
}

const appSettings = new (autoBind(AppSettings))()

export default appSettings
