const PAGE_NAMES = {
  LANDING: 'landing',
  CREATORS: 'creators',
  FEATURES: 'features',
  FOUNDERS: 'founders',
  INVESTORS: 'investors',
  PRICING: 'pricing',
  STUDENTS: 'students',
} as const

type PAGE_NAMES = typeof PAGE_NAMES[keyof typeof PAGE_NAMES]

const HOMEPAGE_EVENT = {
  [PAGE_NAMES.LANDING]: 'homepage_viewed',
  [PAGE_NAMES.PRICING]: 'homepage_pricing_page_viewed',
  [PAGE_NAMES.FEATURES]: 'homepage_feature_page_viewed',
  [PAGE_NAMES.CREATORS]: 'homepage_creator_page_viewed',
  [PAGE_NAMES.STUDENTS]: 'homepage_student_page_viewed',
  [PAGE_NAMES.INVESTORS]: 'homepage_investor_page_viewed',
  [PAGE_NAMES.FOUNDERS]: 'homepage_founder_page_viewed',
} as const

type HOMEPAGE_EVENT = typeof HOMEPAGE_EVENT[keyof typeof HOMEPAGE_EVENT]

export { PAGE_NAMES, HOMEPAGE_EVENT }
