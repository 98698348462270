import { ITypedAxiosGroup } from '@/src/utils/axios'
import { IScriptsLoadingState } from '@/src/setup'
import { ITypedEventLoggers } from '@/src/integrations/logging/event/typedEventLoggers'
import { Amplitude } from '@/src/integrations/logging/client/amplitudeClient'
import { TypedLoggingClient } from '@/src/integrations/logging/client/typedLoggingClient'
import { QuantcastClient } from '@/src/integrations/QuantcastClient'
import { HomepageEvent } from '@/src/integrations/logging/event/homepage'

export default class TypedEventLoggers implements ITypedEventLoggers {
  ampClient: Amplitude
  typedLoggingClient: TypedLoggingClient
  quantcast: QuantcastClient
  homepageEvent: HomepageEvent

  constructor(
    readonly axiosGroup: ITypedAxiosGroup,
    readonly scriptsLoadingState: IScriptsLoadingState,
  ) {
    this.ampClient = new Amplitude()
    this.typedLoggingClient = new TypedLoggingClient(
      this.axiosGroup.loggingAxios,
    )
    const clients = [this.ampClient, this.typedLoggingClient]
    this.quantcast = new QuantcastClient(process.env.QUANTCAST_ID!)
    this.homepageEvent = new HomepageEvent(clients)

    this.scriptsLoadingState.isAmplitudeLoaded = true
  }
}
