import css from '@emotion/css'
import reset from '@/src/styles/reset'
import colors from '@/src/styles/colors'

export default css`
  ${reset}

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font: inherit;
  }

  html,
  body,
  #__next {
    height: 100%;
    margin: 0;
    font-family: 'Pretendard', 'Sans-serif';
    font-size: 10px; /* root font size for rem */
  }

  #__next {
    overflow-x: hidden;
  }

  button {
    all: unset;
    border: none;
    font: inherit;
    cursor: pointer;
    box-sizing: border-box;
  }

  button:focus,
  video:focus {
    outline: 0;
  }

  [contenteditable] {
    outline: 0px solid transparent;
    overflow: auto;
    -ms-overflow-style: none;
  }

  [contenteditable]::-webkit-scrollbar {
    display: none;
  }

  /* contenteditable에 스타일 코드가 붙은 텍스트가 paste됐을 경우, 그 해당 스타일들을 무시하기 위한 속성 선언*/
  [contenteditable] * {
    all: unset !important;
    color: inherit !important;
    line-height: inherit !important;
    font: inherit !important;
    white-space: nowrap !important;
  }

  /* split.js */
  .gutter.gutter-horizontal {
    flex-shrink: 0;
    background: ${colors.gray77()};
    cursor: col-resize;
  }

  a {
    text-decoration: none;
  }

  hr {
    height: 1px;
    margin: 0;
    border: 0;
    border-top: 1px solid ${colors.gray0()};
  }

  [data-scroll-style] {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  [data-scroll-style]:hover {
    ::-webkit-scrollbar {
      display: block;
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
  }
  ${Object.entries(colors).map(
    ([colorName, colorFn]) =>
      colorName.startsWith('gray') &&
      css`
        [data-scroll-style='${colorName.split('gray')[1]}']:hover {
          ::-webkit-scrollbar-thumb {
            background: ${colorFn()};
          }
        }
      `,
  )}
`
