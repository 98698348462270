import '../src/styles/app.css'
import { useEffect } from 'react'
import { useRouter } from 'next/dist/client/router'
import type { AppProps } from 'next/app'
import { Global } from '@emotion/react'
import globalStyles from '@/src/styles/global'
import UserLanguageContextProvider from '@/src/locale/LocaleContext'
import { Selectors } from '@/src/utils/enums'
import Head from 'next/head'
import { wrapWithEventLogger } from '@/src/integrations/logging/component'
import useAppInitialize from '@/src/hooks/useAppInitialize'
import TitleAndDesc from '@/src/components/pages/TitleAndDesc'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  useEffect(() => {
    const rootElem = document.getElementById(Selectors.ROOT)
    if (rootElem) {
      rootElem.scrollTo(0, 0)
    }

    return () => {
      if (rootElem) {
        rootElem.scrollTo(0, 0)
      }
    }
  }, [router.pathname])

  const isInitialized = useAppInitialize()

  return (
    <>
      <Global styles={globalStyles} />
      <UserLanguageContextProvider>
        <TitleAndDesc
          title='Typed — Connecting the Docs'
          description='Trusted by Google, Typed is a collaborative document tool that optimizes teamwork by connecting your team’s scattered docs and workflows.'
        />
        <Head>
          <meta charSet='utf-8' />
          <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
          <title>Typed — Connecting the Docs</title>
          <link rel='canonical' href='https://typed.do' />
          <meta
            property='og:image'
            content='https://static.wixstatic.com/media/512341_451469296aec4ce0a1d8fa514dd7b3c0%7Emv2.png/v1/fit/w_2500,h_1330,al_c/512341_451469296aec4ce0a1d8fa514dd7b3c0%7Emv2.png'
          />
          <meta property='og:image:width' content='2500' />
          <meta property='og:image:height' content='1330' />
          <meta property='og:url' content='https://typed.do' />
          <meta property='og:site_name' content='Typed' />
          <meta property='og:type' content='website' />
          <meta
            name='keywords'
            content='second brain, knowledge management, research tool, organize files, organize folders, google docs, focused work, focused research, productivity'
          />
          <meta name='twitter:card' content='summary_large_image' />
          <meta
            name='twitter:image'
            content='https://static.wixstatic.com/media/512341_451469296aec4ce0a1d8fa514dd7b3c0%7Emv2.png/v1/fit/w_2500,h_1330,al_c/512341_451469296aec4ce0a1d8fa514dd7b3c0%7Emv2.png'
          />
          <meta
            name='facebook-domain-verification'
            content='y022l74giny1aauf665vw44ywvf15b'
          />
        </Head>
        {isInitialized &&
          wrapWithEventLogger(
            <Component {...pageProps} />,
            Component.displayName,
          )}
      </UserLanguageContextProvider>
    </>
  )
}

export default MyApp
