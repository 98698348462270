import { useEffect } from 'react'
import useToggle from '@/src/hooks/useToggle'
import appSettings from '@/src/setup'

export default function useAppInitialize() {
  const [isInitialized, initialize] = useToggle(false)
  useEffect(() => {
    appSettings.initializeAfterMount()
    initialize()
  }, [initialize])

  return isInitialized
}
